@import-normalize;
@import "range.scss";

$yellow: #ffd800;
$green: #57FA57;
$blue: #04f;
$red: #D80000;
$pink: #E36BA6;
$orange: #FCB02E;
$lostred: #E12D2D;
$impressum: #FFDB00;

$background: #000;

@mixin rwd($screen) {
  @media (min-height: $screen+'px') {
      @content;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background;
  color: #fff;
}

@mixin font($font-family, $font-size, $font-weight, $color)
{
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color;
  font-size: $font-size;
  font-weight: $font-weight;
}

$default-font: "Open Sans", sans-serif;
$default-font-color: #fff;
$default-font-size: 15px;

@mixin fontbig($size){
  @include font($default-font, $size+ 0px, bold, $default-font-color);
  @include rwd(667)
  {
    font-size: 5px+$size;
  }
  @include rwd(736)
  {
    font-size: 10px+$size;
  } 
}

@mixin defaultfont()
{
  @include font($default-font, $default-font-size, normal, $default-font-color);
}

.App,
#impressum {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr 130px;
  grid-template-areas: "top" "main" "bottom";
  grid-row-gap: 0;
  width: 100vw;
  padding: 0px 20px 10px;
  height: 100%;
  &.won {
    grid-template-rows: 60px 1fr 0px;
  }
}

.top {
  grid-area: top;
  background: url("/header.png") no-repeat scroll 90% 90%;
}

.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  grid-template-areas: "box" "text";
  grid-area: main;
}

.box { grid-area: box; }

.main .box{
  animation: flicker-in-1 2.2s linear both 600ms;
  border: 6px #fff solid;
  padding: 17px 10px 40px;
  margin: 20px 0 66px;
  
  text-transform: uppercase;
  @include fontbig(30);

  &.yellow {
    border-color: $yellow;
  }

  &.impressum {
    border-color: $impressum;
  }
  
  &.red
  {
    border-color: $red;
  }

  &.lostred
  {
    border-color: $lostred;
  }

  &.pink
  {
    border-color: $pink;
  }

  &.orange
  {
    border-color: $orange;
  }

  &.green
  {
    border-color: $green;
  }

  &.o30{
    animation: none;
    opacity: 0.3;
  }

  &.image
  {
    position: relative;
  
    &::after{
      content: " ";
      width: 123px;
      height: 101px;
      background: $background;
      position: absolute;
      margin: 0 auto;
      right: 50%;
      transform: translate(50%,0 );
      bottom: -66px;
    }
  
    &.ball::after{
      background: $background url("/ball.png") no-repeat scroll 50% 50%;
    }
  
    &.gift::after{
      background: $background url("/gift.png") no-repeat scroll 50% 50%;
    }

    &.gifto::after{
      background: $background url("/giftOpen.png") no-repeat scroll 50% 50%;
    }

    &.sad::after{
      background: $background url("/crumpy.png") no-repeat scroll 50% 50%;
    }
  }
}

.main > .text {
  grid-area: text;
  display: grid;
  justify-content: center;
  align-content: center; 

  @include defaultfont();

  &.icontainer{
    position: relative; 
  
    height: 0; 
    overflow: hidden; 
    width: 100%;
    height: auto;
    & iframe {

      position: absolute; 
      top: 0; 
      left: 0; 
      width: 100%; 
      height: 100%; 
    }
  }
 
  &.text-center{
    text-align: center;
  }

  &.big {
    @include fontbig(20);
    &.waiting{
      @include fontbig(50);
      & > .place{
        @include fontbig(26);
      }
    }
    
  }

  &.small{
    padding: 0 30px;

    @include rwd(667)
    {
      padding: 0 45px;
    }
    @include rwd(736)
    {
      padding: 0 55px;
    } 

    line-height: 1.5em;
  }

  &.end{
    align-content: end;
    & input[type=checkbox] {
      display: none;
      
      &:checked + label {
        display: none;
      }
    }
  }

  &.scroll {
    text-align: center;
    height: 100%;
    margin: 0;
    padding: 0;
    & > div {
      word-wrap: break-word;
      max-width: 100%;
    }
    & h1, & h2, & h3, & h4, & h5{
      padding: 25px 0 10px;
      word-wrap: break-word;
      max-width: 100%;
    }

    & ul{
      padding-left: 35px;
      text-align: left;
    }

    & a{
      color: #7988FF;
      word-break: break-all;
      &:link, &:visited, &:hover, &:active  {
        color: #7988FF;
      }
    }
  }
}

.bottom {
  display: grid;
  grid-area: bottom;
  height: 100%;
  align-content: center;

  & .imprint{
    margin-top: 20px;
    margin-bottom: -20px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    &:link, &:visited, &:hover, &:active{
      text-decoration: none;
      color: #fff;
    }
  }
}

.grid2 {
  grid-column: 1/3;
}

.grid3 {
  grid-column: 1/4;
  justify-content: center;
}

#impressum {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#impressum input[type="checkbox"] {
  display: none;
}

#impressum input[type="checkbox"] + label {
  grid-column: 2;
  grid-row: 3;
  text-align: center;
  font-size: 16px;
}
#impressum input[type="checkbox"]:checked + label {
  color: transparent;
}

#impressum label::selection {
  background-color: transparent;
}
#impressum label::-moz-selection {
  background-color: transparent;
}

#impressum input[type="checkbox"]:checked + label::before {
  content: "X";
  color: #fff;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  width: 100%;
  position: absolute;
  right: 0;
}

.App div.control {
  width: 80%;
  margin: auto;
  grid-row: 3;
}

input[type="range"] {
  @include input-type-range;
  width: 100%;
  grid-row: 1;
  grid-column: 1/4;
}

$shadow-top: -9px;
$shodow-right: 10px;
$transition-timing: 0.2s;
$transition-function: ease-in-out;

@mixin buttonShadow($color) {
  box-shadow: $shodow-right $shadow-top 0px 0px $color;
  &:active {
    box-shadow: $shodow-right/2 $shadow-top/2 0px 0px $color;
    transition: box-shadow $transition-timing $transition-function,
      transform $transition-timing $transition-function;
  }
}

@mixin gradientBoxShadow($gradient, $fallback) {
  &::after {
    background: $fallback;
    background: $gradient;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: $shadow-top;
    left: $shodow-right;

    content: "\00a0";
  }
  &:active::after {
    top: $shadow-top/2;
    left: $shodow-right/2;
    transition: top $transition-timing $transition-function,
      left $transition-timing $transition-function,
      transform $transition-timing $transition-function;
    -webkit-animation-fill-mode: initial; //Changed from both to initial
    animation-fill-mode: initial;
  }
}

@mixin border($color) {
}
a.button{
  text-decoration: none !important;
  padding: 5px 10px;
  @include buttonShadow($green);

}

button, a.button, input.button {
  position: relative;
  border: 0;
  background: #fff;
  width: 200px;
  height: 45px;
  text-align: center;
  text-transform: uppercase;
  align-self: center;
  justify-self: center;
  outline: 0;
  @include font($default-font, 29px, bold, $background);



  &:active {
    transform: translate(-$shadow-top/2, -$shodow-right/2);
  }

  &.play {
    @include buttonShadow($pink)
  }

  &.yellow {
    @include buttonShadow($yellow);
  }

  &.impressum {
    @include buttonShadow($impressum);
  }

  &.lostred {
    @include buttonShadow($lostred);
  }

  &.green {
    @include buttonShadow($green);
  }
}


.chimp button{
  @include buttonShadow($green);
  align-self: end;
  justify-self: center;
  margin-bottom: 20px;
}

.main > .text.chimp{
  display: grid;

  justify-content: stretch;
  align-content: stretch;
  & div {
    display: grid;
    justify-self: center;
    align-self: stretch;


    & input{
      align-self: center;
      height: 40px;
      display: inline-block;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      padding: 10px 20px;
      border: 4px solid #ffffff;
      @include defaultfont();
      color: $default-font-color;
      -o-text-overflow: clip;
      text-overflow: clip;
      background: $background;
      box-shadow: 0;
      text-shadow: none ;
    }
  }
}


.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

@mixin textshadow($color) {
  text-shadow: 2px -3px 0px $color;
}

.glitch {
  @include textshadow(#ed63a8);
  text-align: center;
  font-size: 1.5em;
  font-family: "Press Start 2P", cursive;
}





@keyframes flicker-in-1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

